import { h } from 'preact'
import Match, { Link } from 'preact-router/match'
import BurgerMenu from '../BurgerMenu/BurgerMenu'
import * as PubSub from 'pubsub-js'
import * as classes from './Header.module.css'
import { useEffect, useState } from 'preact/hooks'
import { PAGES_EN } from '../../core/Pages'

const Header = () => {
    const [currentLang, setCurrentLang] = useState()

    const change = () => {
        if (currentLang === 'de') PubSub.publish('LANGUAGE', 'en')
        if (currentLang === 'en') PubSub.publish('LANGUAGE', 'de')
        if (!currentLang) PubSub.publish('LANGUAGE', 'de')

        console.log(currentLang)
    }

    useEffect(() => {
        const sub = PubSub.subscribe('LANGUAGE', mySubscriber)
        return () => PubSub.unsubscribe(sub)
    }, [])

    const mySubscriber = (msg: any, data: any) => {
        setCurrentLang(data)
    }

    return (
        <header className={classes.header}>
            <Link className={classes.claim} href={PAGES_EN.HOME}>
                🛖
            </Link>

            <Match path="/">
                {({ matches }: any) =>
                    matches && (
                        <div className={classes.largeType}>
                            A new myth has been born. An echo of earth pulses
                            through me—<i>I shape, I transform, I shock, I grow</i>.
                            This is the story of <i>the contemporary shaman</i>,
                            and now, I am ready to share it. 🪸
                        </div>
                    )
                }
            </Match>

            <div className={classes.burger}>
                <BurgerMenu />
            </div>
        </header>
    )
}

export default Header
