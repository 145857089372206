import { useEffect, useRef } from 'preact/hooks'

export type TLocale = 'en' | 'de'

const useLocale = () => {
    if (false) {
        return 'de'
    }   
    
    return 'en'
}

export default useLocale
