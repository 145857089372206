import { h } from 'preact'
import { useEffect } from 'preact/hooks'
import * as classes from './Loading.module.css'

const Loading = () => {
    useEffect(() => {
        document.body.classList.add('loading')
        return () => document.body.classList.remove('loading')
    }, [])

    return (
        <div className={classes.wrapper}>
            <div class={classes.emoji}>🌝</div>
        </div>
    )
}

export default Loading
