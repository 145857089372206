export const PAGES_EN = {
    HOME: '/',
    ABOUT: '/about/',
    CODE: '/code-of-ethics/',
    LEGAL: '/legal/',
    CONTACT: '/contact/',
}

export const PAGES_DE = {
    HOME: '/de/',
    ABOUT: '/de/about/',
    CODE: '/de/code-of-ethics/',
    LEGAL: '/de/impressum/',
    CONTACT: '/de/kontakt/',
}

export const getRoutesByLocale = (lang: 'de' | 'en') => {
    if (lang === 'de') {
        return PAGES_DE
    }

    if (lang === 'en') {
        return PAGES_EN
    }
}
