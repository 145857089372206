.wrapper {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
@keyframes rotating {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.emoji {
    animation: rotating 2.33s cubic-bezier(0.21, 0.51, 0.83, 0.67) infinite;
    filter: grayscale(0.5);
    font-size: var(--f-xl);
}
