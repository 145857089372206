.footer {
    padding-bottom: 24px;
    padding-top: 30px;
    margin-top: var(--s-xl);
    border-top: 1px solid rgba(0, 0, 0, 0.09);
    font-size: var(--f-m);
}

.item + .item {
    margin-left: 20px;
}

.item:last-of-type {
    width: 100%;
    margin-left: 0;
    margin-top: 12px;
}

@media (min-width: 500px) {
    .item:last-of-type {
        width: auto;
        margin-left: 20px;
        margin-top: 0;
    }
}

.items {
    display: flex;
    width: 100%;
    font-size: var(--f-m);
    flex-wrap: wrap;
}

@media (min-width: 400px) {
    .items {
        width: auto;
    }
}

@media (min-width: 600px) {
    .code {
        display: inline-block !important;
    }
}

.button {
    border: 3px solid var(--c-border);
    border-radius: 5px;
    padding: 4px;
    padding-left: 8px;
    padding-right: 8px;
    font-weight: 600;

    text-wrap: nowrap;
}
