import { h } from 'preact'
import { Link } from 'preact-router'
import { getRoutesByLocale } from '../../core/Pages'
import useIsInstagram from '../../hooks/useIsInstagram'
import useLocale from '../../hooks/useLocale'
import Container from '../Container/Container'
import * as classes from './Footer.module.css'

// TODO: similar style as header? :D
const Footer = () => {
    const locale = useLocale()
    const routes = getRoutesByLocale(locale)
    const isInstagram = useIsInstagram()

    return (
        <footer className={classes.footer}>
            <Container>
                <div style={{ display: 'flex' }}>
                    <ul
                        className={classes.items}
                        style={{
                            display: 'flex',
                        }}
                    >
                        <li className={classes.item}>
                            <Link href={routes.CODE}>
                                🍵{' '}
                                <span
                                    style={{
                                        display: isInstagram
                                            ? 'inline-block'
                                            : 'none',
                                    }}
                                    className={classes.code}
                                >
                                    {' '}
                                    code of
                                </span>{' '}
                                ethics
                            </Link>{' '}
                        </li>

                        {/* 
                        <li hidden className={classes.item}>
                            <Link href={routes.LEGAL}>⚖️ legal</Link>{' '}
                        </li>
                        */}

                        <li className={classes.item}>
                            <Link href={routes.CONTACT}>🤝🏽 contact</Link>{' '}
                        </li>

                        {!isInstagram && (
                            <li className={classes.item}>
                                <Link href="https://www.instagram.com/the.contemporary.shaman/">
                                    🧙🏻‍♂️ social
                                </Link>{' '}
                            </li>
                        )}

                        <li className={classes.item}>
                            <Link href="https://thecontemporaryshaman.setmore.com/sessions">
                                <span className={classes.button}>
                                    ↗ book a session
                                </span>
                            </Link>{' '}
                        </li>
                    </ul>
                </div>
            </Container>
        </footer>
    )
}

export default Footer
