.burger {
    color: black;
    cursor: pointer;
    z-index: var(--z-highest);
    font-weight: bold;
    padding: 0 10px 10px 18px;
    margin: 6px -10px -10px -10px;
}

.active .drawer {
    opacity: 1;
}

.drawer {
    align-items: center;
    width: 100%;
    height: 100vh;
    padding: 0;
    position: fixed;
    background-color: var(--page-background);
    color: black;
    top: 0;
    right: 0;
    left: 0;
    z-index: var(--z-highest);
    transition: opacity 225ms cubic-bezier(0, 0, 0.2, 1) 2s;
    opacity: 0;
    display: block;
}

.drawerMenu {
    width: 100%;
    text-align: center;
    font-size: var(--f-l);
    font-family: var(--f-family);
}

.drawerMenuItem + .drawerMenuItem {
    margin-top: 38px;
}

.closeIcon {
    position: absolute;
    top: 16px;
    right: 28px;
    font-size: var(--f-xl);
    padding-left: 12px;
    padding-right: 12px;
    margin-left: -12px;
    margin-right: -12px;
}

.activeNavItem {
    border-bottom: 1px solid;
}

.backgroundBlur {
    -webkit-tap-highlight-color: transparent;
    background-color: rgba(0, 0, 0, 0.7);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: var(--z-lowest);
}
