import { h, FunctionComponent } from 'preact'
import * as classes from './Container.module.css'

interface IProps {
    style?: Record<string, any>
    className?: string
    maxWidth?: boolean
}

const Container: FunctionComponent<IProps> = (props) => {
    const { className, style, ...rest } = props

    let css = `${classes.container} ${className} `

    return (
        <section className={css} style={style} {...rest}>
            {props.children}
        </section>
    )
}

export default Container
